.closeSvg {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}
.closeSvg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.modalContent {
  padding: 40px 24px 60px;
}
.modalWrapper :global .ant-modal-body {
  padding: 0px;
}
.modalWrapper :global .ant-modal {
  max-width: 360px;
  width: 100% !important;
  padding-bottom: 0px;
}
.form {
  margin-top: 36px;
}
.form p {
  font-size: 0.875em;
}
.form p:last-child {
  color: var(--color-brand);
  margin-bottom: 0;
}
.form :global .ant-form-item {
  margin-bottom: 8px;
}
.form :global .ant-btn {
  width: 100%;
  margin-top: 8px;
  padding: 10px 40px;
  height: fit-content;
}
.svgWrapper {
  margin-top: 16px;
  text-align: center;
}
.title {
  font-size: 1.5em;
  line-height: 1.1em;
  text-align: left;
  margin-bottom: 0;
}
body[data-domain='nnormal'] .title,
body[data-domain='nnormal'] .submitBtn {
  font-family: var(--font-secondary);
}
.info {
  font-size: 0.875em;
  margin-top: 0px;
  padding-top: 24px;
  text-align: left;
}
.info ul {
  padding: 0;
}
.info li {
  list-style-type: '· ';
  list-style-position: inside;
  margin-top: 1em;
}

.accountIcon {
  display: grid;
}
.accountIcon:hover {
  cursor: pointer;
}

.accountIcon .label {
  font-family: var(--font-primary);
  font-size: var(--description-size);
  text-transform: uppercase;
}
body[data-domain='nnormal'] .accountIcon .label {
  font-size: 1em;
  text-transform: unset;
}

.globaleOrdersTitle {
  font-weight: bold;
  text-align: center;
  margin-top: 1em;
}

.ableToList {
  margin-bottom: 3em;
}

.ableToList li {
  margin-bottom: 1em;
}

.buttonsWrapper {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buttonsWrapper a {
  border: 1px solid grey;
  display: flex;
  padding: 0.5em 3em;
}

.buttonsWrapper a:hover {
  border: 1px solid black;
}

@media (min-width: 1440px) {
  .modalWrapper :global .ant-modal {
    max-width: 788px;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 34px;
  }
  .modalContent {
    padding: 40px 40px 6px;
  }
  .info {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1439.5px) {
  .label {
    display: none;
  }

  .accountIcon {
    background: url('/assets-new/orders.svg') center center no-repeat;
    background-size: 18px;
    height: 19px;
    text-align: center;
    width: 18px;
    background-position: 50% 0%;
  }
}
@media (max-width: 991.5px) {
  .accountIcon {
    height: 24px;
    width: 27px;
    background-size: 18px;
  }
}
@media (max-width: 767.5px) {
  .modalWrapper :global .ant-modal {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }
  .modalWrapper :global .ant-modal-content {
    max-width: 100vw;
  }
  .modalContent {
    min-height: 100vh;
    max-width: 360px;
    margin: 0 auto;
  }
}
